import store from '@/store'

import { ref, getCurrentInstance } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { fetchCourses } from '../useCourse'


export default function useCourseCreate() {
    // Stepper's active step
    const vm = getCurrentInstance().proxy
   

    const addCourse = async (courseData) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        store
            .dispatch('app-academy/addCourse', courseData)
            .then((response) => {
                const { data } = response
                fetchCourses()
                
                store.commit('comp-alert/SET_MESSAGE', {})
            })
            .catch(error => {
                store.commit('comp-alert/SET_MESSAGE', error.response.data)
            })
    }
    const updateCourse = async (courseData) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        store
            .dispatch('app-academy/updateCourse', courseData)
            .then((response) => {
                const { data } = response
                
                store.commit('comp-alert/SET_MESSAGE', {})
            })
            .catch(error => {
                store.commit('comp-alert/SET_MESSAGE', error.response.data)
            })
    }



    return {
        addCourse,
        updateCourse
    }

}