<template>
    <v-navigation-drawer
    :value="isAddNewCourseSidebarActive"
    temporary
    touchless
    width="100%"
    :right="!$vuetify.rtl"
    app
    @input="(val) => {$emit('update:is-add-new-course-sidebar-active', val);}"
  >
    <v-card>
        <alert />
        <v-form
            ref="courseForm"
        >
        <v-card-title>
            {{ localCourse.course_id ? "Update Course" : "Add New Course" }}
            <v-spacer></v-spacer>
            <v-btn
                outlined
                color="success"
                @click="submitCourse"
            >
                
            {{ localCourse.course_id ? "Update" : "Create" }}
            </v-btn>
            <v-btn
            icon
            small
            @click="closeDrawer"
            >
            <v-icon size="22">
                {{ icons.mdiClose }}
            </v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle>
            Just fill the form for your course.
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <v-text-field
                        v-model="localCourse.name"
                        label="Course Name"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                    >

                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <v-autocomplete
                        v-model="localCourse.level"
                        :items="levelOptions"
                        label="Level"
                        outlined
                        dense
                        small-chip
                        hide-details="auto"
                        :rules="[validators.required]"
                    >
                        
                        <template #selection="{ item }">
                            <v-chip :color="`${resolveCourseLevelVariant(item.text)}`" small>{{item.text}}</v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <v-autocomplete
                        v-model="localCourse.instructors"
                        :loading="instructorLoading"
                        :items="instructorOptions"
                        :search-input.sync="instructorSearch"
                        item-text="full_name"
                        item-value="id"
                        label="Instructors"
                        outlined
                        dense
                        hide-details="auto"
                        placeholder="Instructors"
                        @change="instructorSearch=''"
                        persistent-hint
                        multiple
                        :rules="[validators.required]"
                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                        >
                        <!-- Options Slot -->
                        <template #item="{ item }">
                            <div class="d-flex align-center">
                            <v-avatar
                                    :color="stringToHslColor(item.full_name) || primary"
                                    size="25"
                                    class="me-2"
                                >
                                    <span :class="`white--text text-xs`">{{avatarText(item.full_name)}}</span>
                                </v-avatar>
                            <span class="text-sm">{{ item.full_name }}</span>
                            </div>
                        </template>
                        </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                    
                    <v-textarea
                        v-model="localCourse.desc"
                        label="Course Description"
                        outlined
                        dense
                        hide-details="auto"
                    >
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row>
                
                <v-col
                    cols="12"
                    md="4"
                    sm="6"
                >
                    <v-autocomplete
                        v-model="localCourse.category"
                        :items="categoriesOptions"
                        :loading="categoriesLoading"
                        item-text="name"
                        item-value="id"
                        label="Category"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                    >

                    </v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    sm="6"
                >
                    <v-autocomplete
                        v-model="localCourse.languages"
                        :items="languagesOptions"
                        :loading="languagesLoading"
                        item-text="name"
                        item-value="id"
                        label="Languages"
                        outlined
                        dense
                        multiple
                        hide-details="auto"
                        :rules="[validators.requiredArray]"
                    >

                    </v-autocomplete>
                    
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    sm="6"
                >
                    <v-autocomplete
                        v-model="localCourse.status"
                        :items="statusOptions"
                        label="Status"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required]"
                    >

                    </v-autocomplete>
                </v-col>
            </v-row>
            
        </v-card-text>

        <v-card>
            <v-card-title>
                Curriculum
            </v-card-title>

            <v-card
                v-for="(section, i) in localCourse.sections"
                :key="i"
                outlined
                class="ma-2"
            >
                <v-card-text
                >
                    <v-row>
                        <v-col
                            cols="12"
                            md="11"
                            sm="11"
                        >
                            <v-text-field
                                v-model="section.name"
                                label="Section Title"
                                dense
                                outlined
                                hide-details="auto"
                                :rules="[validators.required]"
                            >
                                
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            md=1
                            sm="1"
                        >
                            <v-btn
                                color="error"
                                icon
                                outlined
                                @click="removeSection(i)"
                                hide-details="auto"
                            >
                                <v-icon>
                                    {{ icons.mdiDelete }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <v-row>

                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-textarea
                                v-model="section.desc"
                                label="Section Description"
                                outlined
                                dense
                                hide-details="auto"
                            >


                            </v-textarea>
                        </v-col>
                    </v-row>
                    
                    <v-row>

                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-card>
                                <v-card-title>Lectures</v-card-title>
                                <v-card-text>
                                    <v-card
                                        v-for="(lecture, j) in section.lectures"
                                        :key="j"
                                        outlined
                                        class="ma-2"
                                    >
                                        <v-card-text>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    md="11"
                                                >
                                                    <v-text-field
                                                        v-model="lecture.name"
                                                        label="Lecture Title"
                                                        outlined
                                                        dense
                                                        hide-details="auto"
                                                        :rules="[validators.required]"
                                                    >

                                                    </v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    md="1"
                                                >
                                                    <v-btn
                                                        icon
                                                        color="error"
                                                        outlined
                                                        @click="removeLecture(i, j)"
                                                    >
                                                        <v-icon>
                                                            {{ icons.mdiDelete }}
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                        v-model="lecture.desc"
                                                        label="Lecture Description"
                                                        outlined
                                                        dense
                                                        hide-details="auto"
                                                    >

                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    md="3"
                                                >
                                                    <v-text-field
                                                        v-model="lecture.duration"
                                                        label="Duration in min"
                                                        type="number"
                                                        outlined
                                                        dense
                                                        hide-details="auto"
                                                    >
                                                        
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    md="12"
                                                >
                                                    <v-textarea
                                                        v-model="lecture.file"
                                                        label="File Link"
                                                        outlined
                                                        dense
                                                        hide-details="auto"
                                                    >
                                                        
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>


                                    </v-card>
                                    <v-btn
                                        color="info"
                                        width="100%"
                                        @click="addNewLecture(i)"
                                    >
                                        Add New Lecture
                                    </v-btn>

                                </v-card-text>
                            </v-card>


                        </v-col>
                    </v-row>
                </v-card-text>
                
            </v-card>
            

            <v-card-text
                
            >
            <v-btn
                color="primary"
                width="100%"
                @click="addNewSection"
            >
                Add New Section
            </v-btn>

            </v-card-text>
            
        </v-card>
    </v-form>

    </v-card>
    </v-navigation-drawer>
</template>

<script>
import { ref, watch, onMounted, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import {
    mdiDelete,
    mdiClose
} from '@mdi/js'
import academyStoreModule from '../academyStoreModule'
import store from '@/store'
import { required, requiredArray } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import { stringToHslColor} from '@core/utils'

import useCourseCreate from './useCourseCreate'
import useCourse from '../useCourse'
import Alert from '@/views/components/alert/Alert.vue'

export default ({
    components:{
        Alert
    },
    model: {
        prop: 'isAddNewCourseSidebarActive',
        event: 'update:is-add-new-course-sidebar-active',
    },
    props: {
        isAddNewCourseSidebarActive: {
            type: Boolean,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        }
    },
    setup(props, { emit }) {
        const ACADEMY_APP_STORE_MODULE_NAME = 'app-academy'
        // Register module
        if (!store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.registerModule(ACADEMY_APP_STORE_MODULE_NAME, academyStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACADEMY_APP_STORE_MODULE_NAME)
        })

        const vm = getCurrentInstance().proxy

        const localCourse = ref({})
        const levelOptions = ref([
            {
                value: "CORE",
                text: "Core"
            },
            {
                value: "INTERMEDIATE",
                text: "Intermediate"
            },
            {
                value: "ADVANCED",
                text: "Advanced"
            },
        ])
        const statusOptions = ref([
            {
                value: "DRAFT",
                text: "Draft"
            },
            {
                value: "REVIEW",
                text: "Review"
            },
            {
                value: "PUBLISHED",
                text: "Published"
            },
            {
                value: "DISABLED",
                text: "Disabled"
            },
        ])

        

        const {
            addCourse,
            updateCourse
        } = useCourseCreate()

        const {
            resolveCourseLevelVariant,

            fetchCategories,
            fetchLanguages,
            fetchInstructors,

            categoriesLoading,
            categoriesOptions,
            categoriesSearch,
            languagesLoading,
            languagesOptions,
            languagesSearch,
            instructorLoading,
            instructorOptions,
            instructorSearch,

        } = useCourse()

        const init = () => {
            vm.$refs.courseForm.resetValidation()

            store.commit('comp-alert/SET_MESSAGE', {})
            localCourse.value = JSON.parse(JSON.stringify(props.course))

        }
        

        const newSection = ref({
            name:"",
            desc:"",
            lectures:[]
        })
        
        const newLecture = ref({
            name:"",
            desc:"",
            duration:0,
            file: ""
        })


        const addNewSection = () => {
            const localNewSection = JSON.parse(JSON.stringify(newSection.value))
            localCourse.value.sections.push(localNewSection)
        }

        const removeSection = (sectionIndex) => {
            localCourse.value.sections.splice(sectionIndex, 1)
        }
        
        const addNewLecture = (sectionIndex) => {
            const localNewLecture = JSON.parse(JSON.stringify(newLecture.value))
            localCourse.value.sections[sectionIndex].lectures.push(localNewLecture)
        }

        const removeLecture = (sectionIndex, LectureIndex) => {
            localCourse.value.sections[sectionIndex].lectures.splice(LectureIndex, 1)
        }

        const submitCourse = () => {
            
            const isStepValid = vm.$refs.courseForm.validate()

            if (isStepValid) {
                const course = JSON.parse(JSON.stringify(localCourse.value))
                course.instructors = course.instructors.map(instructor => {
                    if(instructor.id) return instructor.id
                    return instructor
                })
                course.languages = course.languages.map(lang => {
                    if(lang.id) return lang.id
                    return lang
                })
                if(course.category.id != undefined) course.category = course.category.id
                console.log(course)
                if (course.slug) updateCourse(course)
                else addCourse(course)
            }
        }

        const closeDrawer = () => {
            emit('update:is-add-new-course-sidebar-active', false)
        }

        watch(
            ()=> props.course,
            () => {
                localCourse.value = JSON.parse(JSON.stringify(props.course))
            }
        )
        watch(
            ()=> props.isAddNewCourseSidebarActive,
            (newVal, oldVal) => {
                if(newVal && !props.course.slug) init()
            }
        )

        const timeStringToFloat = (time) => {
            var hoursMinutes = time.split(/[.:]/);
            var hours = parseInt(hoursMinutes[0], 10);
            var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

            return (hours*60) + minutes;
        }

        onMounted(() => {
            init()
            fetchCategories()
            fetchLanguages()
            fetchInstructors()
            
        })

        return {
            resolveCourseLevelVariant,

            localCourse,
            submitCourse,
            closeDrawer,

            levelOptions,
            statusOptions,

            addNewSection,
            removeSection,
            addNewLecture,
            removeLecture,

            categoriesLoading,
            categoriesOptions,
            categoriesSearch,
            languagesLoading,
            languagesOptions,
            languagesSearch,
            instructorLoading,
            instructorOptions,
            instructorSearch,
            avatarText,
            stringToHslColor,

            icons: {
                mdiDelete,
                mdiClose
            },

            validators: {
                required,
                requiredArray
            }
        }
    }
})
</script>

<style>

</style>